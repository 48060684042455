<template>
  <div style="height: 100%  ">
    <!-- <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click.stop="openNav = !openNav" height="18" width="22"></v-app-bar-nav-icon> -->
    <!--    <v-btn class="ml-4 hidden-sm-and-down" light elevation="1" icon v-if="$vuetify.breakpoint.mdAndDown" @click.stop="openNav = !openNav" small>-->
    <!--      <img src="/static/svg/mobile-profile-open-nav.svg"/>-->
    <!--    </v-btn>-->
    <!-- calc(100vh - 100px) -->
    <!--    <v-navigation-drawer width="fix-content" :height="$vuetify.breakpoint.mdAndDown ? 'calc(100vh - 100px)' : ''" class="desktop-navigation-drawer hidden-sm-and-down" :class="$vuetify.breakpoint.mdAndDown ?'mt-2 ml-1': ''" v-model="openNav" :absolute="$vuetify.breakpoint.mdAndDown ? true :false" :left="$vuetify.breakpoint.mdAndDown ? true :false" :temporary="$vuetify.breakpoint.mdAndDown ? true :false"-->
    <!--      :permanent="$vuetify.breakpoint.mdAndDown ? false :true">-->
    <!--      -->
    <!--      &lt;!&ndash; <v-divider class="my-5 mx-4"></v-divider>-->
    <!--                <div v-if="!isLoggedIn">-->
    <!--                <v-row>-->
    <!--                  <v-col cols=12 class="mx-4">-->
    <!--                    {{ $t(`label.welcomeToBrand`) }}-->
    <!--                  </v-col>-->
    <!--                </v-row>-->
    <!--                <v-row justify="space-between" class="mx-1">-->
    <!--                    <v-col cols="5">-->
    <!--                        <v-btn @click="this.openLoginDialog" color="black" class="mr-1 primary-button embedded-mobile-login-button font-weight-bold yellow&#45;&#45;text">{{ $t(`button.login`) }}-->
    <!--                        </v-btn>-->
    <!--                    </v-col>-->
    <!--                    <v-col cols="7">-->
    <!--                        <app-button :customClass= "'buttonPrimary theme-button embedded-mobile-register-button'" :action="this.openRegisterDialog" :title="$t(`label.signUp`)"></app-button>-->
    <!--                    </v-col>-->
    <!--                </v-row>-->
    <!--                </div> &ndash;&gt;-->
    <!--    </v-navigation-drawer>-->

    <v-card style="border-radius: 10px;height:100%">
      <v-row no-gutters class="pa-4 desktop-left-panel">
        <span>{{ $t(`label.welcomeBack`) }}</span>
        <v-col cols="12">
          <!--          <v-card outlined class="grey-card">-->
          <!--            <v-card-text class="pa-2">-->
          <!--              &lt;!&ndash;              <v-icon class="mr-3">mdi-account-circle-outline</v-icon>&ndash;&gt;-->
          <!--              <v-col cols="2">-->
          <!--                <img src="/static/svg/account-grey.svg" class="left-panel-icon" />-->
          <!--              </v-col>-->
          <!--              <v-col>-->
          <!--                <span class="blue-text">{{ memberInfo ? memberInfo.name : '' }}</span>-->
          <!--              </v-col>-->
          <!--            </v-card-text>-->
          <!--          </v-card>-->
          <div class="grey-card">
            <v-row no-gutters class="pa-3 card-row">
              <img src="/static/svg/account-grey2.svg" class="left-panel-icon mr-2" />
              <span class="panel-blue-text">{{ memberInfo ? memberInfo.memberCode : '' }}</span>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12">
          <!--          <v-card outlined class="grey-card">-->
          <!--            <v-card-subtitle>-->
          <!--              <img src="/static/svg/bettingPass-grey.svg" width="25px" height="25px" />-->

          <!--              <span class="mr-1">{{ $t(`mobileMenuTitle.bettingPass`) }}</span>-->
          <!--              <span class="mr-1">{{ $t(`label.level`) }}</span>-->
          <!--              <span class="blue-text">X</span>-->
          <!--            </v-card-subtitle>-->
          <!--            <v-card-text class="pa-2"></v-card-text>-->
          <!--          </v-card>-->
          <div class="grey-card">
            <v-row no-gutters class="pa-3 card-row">
              <v-col cols="4"><img src="/static/svg/bettingPass-grey.svg" class="left-panel-icon mr-2" /></v-col>
              <v-col>
                <span class="mr-1">{{ $t(`mobileMenuTitle.bettingPass`) }}</span>
                <span class="panel-blue-text">{{ $t(`label.level`) }} {{ vipProgressLevel }}</span>
              </v-col>
              <!--              <img src="/static/svg/bettingPass-grey.svg" class="left-panel-icon mr-2" />-->
              <!--              <span class="mr-1">{{ $t(`mobileMenuTitle.bettingPass`) }} {{ $t(`label.level`) }}</span>-->
              <!--&lt;!&ndash;              <span class="mr-1"></span>&ndash;&gt;-->
              <!--              <span class="blue-text">X</span>-->
              <v-col cols="12" class="mt-2">
                <v-progress-linear rounded :indeterminate="isProgressLoading" color="yellow" background-color="grey" :value="progressValue"></v-progress-linear>
              </v-col>
              <v-col cols="12" class="mt-1">
                <span class="progress-text">{{ vipProgress ? vipProgress.current_deposit_progress + '/' + vipProgress.required_deposit_amount : '' }}</span>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <!--viptier progress-->
        <v-col cols="12">
          <!--          <v-card outlined class="grey-card">-->
          <!--            <v-card-subtitle>-->
          <!--              <img src="/static/svg/bettingPass-grey.svg" width="25px" height="25px" />-->

          <!--              <span class="mr-1">{{ $t(`mobileMenuTitle.bettingPass`) }}</span>-->
          <!--              <span class="mr-1">{{ $t(`label.level`) }}</span>-->
          <!--              <span class="blue-text">X</span>-->
          <!--            </v-card-subtitle>-->
          <!--            <v-card-text class="pa-2"></v-card-text>-->
          <!--          </v-card>-->
          <div class="grey-card">
            <v-row no-gutters class="pa-3 card-row">
              <v-col cols="4" v-if="memberVipTierProgress && memberVipTierProgress.image"><img :src="`${memberVipTierProgress.image}`" class="left-panel-icon mr-2" /></v-col>
              <v-col cols="4" v-else><img :src="`/static/image/viptier/${tierColorThemeName}.png`" class="left-panel-icon mr-2" /></v-col>
              <v-col>
                <span class="mr-1">{{ $t(`label.vipTier`) }}</span>
                <span class="panel-blue-text">{{ $t(`label.level`) }} {{ viptierCurLevel }}</span>
              </v-col>
              <!--              <img src="/static/svg/bettingPass-grey.svg" class="left-panel-icon mr-2" />-->
              <!--              <span class="mr-1">{{ $t(`mobileMenuTitle.bettingPass`) }} {{ $t(`label.level`) }}</span>-->
              <!--&lt;!&ndash;              <span class="mr-1"></span>&ndash;&gt;-->
              <!--              <span class="blue-text">X</span>-->
              <v-col cols="12" class="mt-2">
                <v-progress-linear rounded :indeterminate="isProgressLoading" color="yellow" background-color="grey" :value="vipTierProgressPercent"></v-progress-linear>
              </v-col>
              <v-col cols="12" class="mt-1">
                <span class="progress-text">{{ progressLabel }}</span>
                <!--                <div v-if="viptierCurLevel < 7">-->
                <!--                  <span v-if="vipTierProgressPercent === 100 && vipTierUpgrade === null">-->
                <!--                    {{ $t(`label.vipTierPendingUpgrade`) }}-->
                <!--                  </span>-->
                <!--                  <span v-else class="progress-text">{{ vipTierUpgrade ? currentUpgradeDeposit + '/' + requiredUpgradeDeposit : '' }}</span>-->
                <!--                </div>-->
                <!--                <div v-else>-->
                <!--                  <span v-if="vipTierProgressPercent === 100">-->
                <!--                    {{ $t(`label.vipTierRetentionFulFilled`) }}-->
                <!--                  </span>-->
                <!--                  <span v-else class="progress-text">{{ vipTierDowngrade ? currentUpgradeDeposit + '/' + requiredUpgradeDeposit : '' }}</span>-->
                <!--                </div>-->
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12">
          <v-divider class="my-3"></v-divider>
        </v-col>
        <v-col cols="12">
          <div class="grey-card">
            <v-row no-gutters class="pa-3 card-row">
              <v-col class="g-text" cols="12">
                <span>{{ $t(`label.coinsRewards`) }}</span>
              </v-col>
              <v-col class="g-text" cols="12">
                <span class="panel-blue-text">{{ memberInfo ? memberInfo.rewardBalance : '' }}</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-btn class="nav-btn" @click="goToRewards">
                  {{ $t(`label.goToRewards`) }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="grey-card">
            <v-row no-gutters class="pa-3 card-row">
              <v-col class="g-text" cols="12">
                <span>{{ $t(`label.referralWallet`) }}</span>
              </v-col>
              <v-col class="g-text" cols="12">
                <span class="panel-blue-text">{{ redeemCommissionAmt | currency(currentCurrency) }}</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-btn class="nav-btn" @click="redeemCommissionConfirmationDialog">
                  {{ $t(`button.redeem`) }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="mt-2">
                <p style="font-size: .80rem">{{ $t(`message.bankLeftPane_referralWalletDesc`) }}</p>
              </v-col>
              <v-col cols="12" class="mt-2">
                <!--                <v-text-field solo-inverted v-model.trim="referralOverview.my_referral_code" :readonly="true"></v-text-field>-->
                <div class="share-div">
                  <div class="referralCode-div" style="">
                    <span class="grey-text-field ref-text panel-blue-text pa-2">{{ referralOverview.my_referral_code }}</span>
                  </div>
                  <div class="share-btn-div">
                    <v-btn icon class="share-btn" @click="share">
                      <!--                      <v-icon class="share-icon">mdi-share-outline</v-icon>-->
                      <img src="/static/svg/share-outline.svg" />
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <!--      <v-row>-->
      <!--        <v-col>-->
      <!--          -->
      <!--        </v-col>-->
      <!--      </v-row>-->
    </v-card>

    <!--    old dekstop bankpage navigation pane-->
    <!--    <v-list nav dense style="border-radius: 10px">-->
    <!--      <v-list-item-group active-class="profile-nav-active">-->
    <!--        &lt;!&ndash;        <v-list-item v-if="$vuetify.breakpoint.mdAndDown" class="pl-0 nav-btn" active-class="white" @click.stop="openNav = !openNav" >&ndash;&gt;-->
    <!--        &lt;!&ndash;          <v-list-item-title>&ndash;&gt;-->
    <!--        &lt;!&ndash;            <img src="/static/svg/mobile-profile-close-nav.svg"/>&ndash;&gt;-->
    <!--        &lt;!&ndash;          </v-list-item-title>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </v-list-item>&ndash;&gt;-->
    <!--        <v-list-item>-->
    <!--          <v-list-item-title class="nav-title">-->
    <!--            {{ $t(`label.bankingProfile`) }}-->
    <!--          </v-list-item-title>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.DEPOSIT, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-deposit class="mobile-icon-deposit"></account-deposit>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.deposit`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.WITHDRAWAL, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-withdrawal class="mobile-icon"></account-withdrawal>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.withdrawal`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.TRANSACTION_HISTORY, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-history class="mobile-icon"></account-history>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.history`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.TURNOVER, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-history class="mobile-icon"></account-history>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.turnoverHistory`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.PLAYER_HISTORY, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-history class="mobile-icon"></account-history>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.playerHistory`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.CLAIM_VOUCHER, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-reward class="mobile-icon"></account-reward>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.claimVoucher`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button">-->
    <!--          <v-list-item-title class="nav-title">-->
    <!--            {{ $t(`label.userProfile`) }}-->
    <!--          </v-list-item-title>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.PERSONAL, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-profile class="mobile-icon"></account-profile>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.myProfile`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.INBOX, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon class="pt-1">-->
    <!--            <account-inbox class="mobile-icon"></account-inbox>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.inbox`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.CHANGE_PASSWORD, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-password class="mobile-icon"></account-password>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.changePassword`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.BANK_ACCOUNT, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-bank class="mobile-icon"></account-bank>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.bankDetail`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.REWARDS, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-reward class="mobile-icon"></account-reward>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.rewards`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        <v-list-item class="profile-nav-button" :to="{ name: routeName.VIP, params: { lang: this.$route.params.lang } }">-->
    <!--          <v-list-item-icon>-->
    <!--            <account-vip class="mobile-icon"></account-vip>-->
    <!--          </v-list-item-icon>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ $t(`label.vip`) }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--        &lt;!&ndash;          <v-list-item class="profile-nav-button" v-if="affiliateBonus.status == 1" :to="{ name: routeName.AFFILIATE_REFERRAL, params: { lang: this.$route.params.lang } }">&ndash;&gt;-->
    <!--        &lt;!&ndash;            <v-list-item-icon>&ndash;&gt;-->
    <!--        &lt;!&ndash;              <account-vip class="mobile-icon"></account-vip>&ndash;&gt;-->
    <!--        &lt;!&ndash;            </v-list-item-icon>&ndash;&gt;-->
    <!--        &lt;!&ndash;            <v-list-item-content>&ndash;&gt;-->
    <!--        &lt;!&ndash;              <v-list-item-title>{{ $t(`label.referral`) }}</v-list-item-title>&ndash;&gt;-->
    <!--        &lt;!&ndash;            </v-list-item-content>&ndash;&gt;-->
    <!--        &lt;!&ndash;            &lt;!&ndash;<v-list-item-content style="width: 0px;height: 40px;margin-left: 0px">&ndash;&gt;-->
    <!--        &lt;!&ndash;              <v-img src="/static/svg/game-icon-new.svg" width="25px" height="25px"></v-img>&ndash;&gt;-->
    <!--        &lt;!&ndash;            </v-list-item-content>&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;          </v-list-item>&ndash;&gt;-->
    <!--      </v-list-item-group>-->
    <!--    </v-list>-->
  </div>
</template>

<script>
import { DevicePlatform } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import { errorCodeHelper, locale, uiHelper } from '@/util'
import AccountBank from '@/assets/svg/profile/bank.svg'
import AccountDeposit from '@/assets/svg/profile/deposit.svg'
import AccountHistory from '@/assets/svg/profile/history.svg'
import AccountInbox from '@/assets/svg/profile/inbox.svg'
import AccountPassword from '@/assets/svg/profile/password.svg'
import AccountProfile from '@/assets/svg/profile/profile.svg'
import AccountReward from '@/assets/svg/profile/reward.svg'
import AccountVip from '@/assets/svg/profile/vip.svg'
import AccountWithdrawal from '@/assets/svg/profile/withdrawal.svg'
import { AFFILIATE_ONE_TIME_BONUS_DETAIL } from '@/store/affiliate.bonus.module'
import { REFERRAL_POST_COMMISSION, REFERRAL_RESET_COMMISSION } from '@/store/referral.module'
import stringFormat from 'string-format'
import { MEMBER_VIP_TIER_PROGRESS } from '@/store/member.module'

export default {
  name: 'appBankAndProfileSidePanel',
  components: {
    AccountBank,
    AccountDeposit,
    AccountHistory,
    AccountInbox,
    AccountPassword,
    AccountProfile,
    AccountReward,
    AccountVip,
    AccountWithdrawal
  },
  data: () => ({
    progressLabel: '',
    currentUpgradeDeposit: 0,
    requiredUpgradeDeposit: 0,
    tierColorThemeName: 'newmember',
    viptierCurLevel: -1,
    vipTireDepositProgress: 0,
    vipTierProgressPercent: 0,
    stringFormat: stringFormat,
    currentCurrency: uiHelper.getCurrency(),
    share_url: '',
    redeemCommissionAmt: 0,
    progressValue: 0,
    isProgressLoading: true,
    vipProgressLevel: 0,
    devicePlatform: DevicePlatform,
    uihelper: uiHelper,
    openNav: false,
    routeName: ROUTE_NAME,
    bankLink: [
      {
        text_key: 'deposit',
        routeName: ROUTE_NAME.DEPOSIT
      },
      {
        text_key: 'withdrawal',
        routeName: ROUTE_NAME.WITHDRAWAL
      },
      {
        text_key: 'transfer',
        routeName: ROUTE_NAME.TRANSFER
      },
      {
        text_key: 'history',
        routeName: ROUTE_NAME.TRANSACTION_HISTORY
      },
      {
        text_key: 'turnover',
        routeName: ROUTE_NAME.TURNOVER
      },
      {
        text_key: 'player-history',
        routeName: ROUTE_NAME.PLAYER_HISTORY
      },
      {
        text_key: 'bankAccount',
        routeName: ROUTE_NAME.BANK_ACCOUNT
      }
    ],
    profileLink: [
      {
        text_key: 'account',
        routeName: ROUTE_NAME.PROFILE
      },
      {
        text_key: 'personal',
        routeName: ROUTE_NAME.PERSONAL
      },
      {
        text_key: 'announcement',
        routeName: ROUTE_NAME.ANNOUNCEMENT
      },
      {
        text_key: 'referral',
        routeName: ROUTE_NAME.AFFILIATE_REFERRAL
      }
    ],
    affiliateLink: [
      {
        text_key: 'qrCode',
        routeName: ROUTE_NAME.AFFILIATE_LINK
      },
      {
        text_key: 'withdrawal',
        routeName: ROUTE_NAME.AFFILIATE_WITHDRAWAL
      },
      {
        text_key: 'history',
        routeName: ROUTE_NAME.AFFILIATE_TRANSACTION
      },
      {
        text_key: 'bankAccount',
        routeName: ROUTE_NAME.AFFILIATE_BANK_ACCOUNT
      }
    ]
  }),
  created() {
    if (this.vipProgress && this.vipProgress.required_deposit_amount >= 0 && this.vipProgress.current_deposit_progress >= 0) {
      this.progressValue = (this.vipProgress.current_deposit_progress / this.vipProgress.required_deposit_amount) * 100
      this.isProgressLoading = false
      this.vipProgressLevel = this.vipProgress.current_level_name.indexOf('Level') != -1 ? this.vipProgress.current_level_name.split(' ')[1] : ''
    }
    this.getVipTierProgress()
    this.setVipTierLevel()

    if (!_.isEmpty(this.referralOverview)) {
      this.share_url = uiHelper.getHostname() + '?refer_code=' + this.referralOverview.my_referral_code
      this.redeemCommissionAmt = this.$store.state.referral.overview.commission_balance.toFixed(2)
    }
  },
  computed: {
    vipTierDowngrade() {
      return this.$store.state.member.vipTierProgress.downgrade
    },
    vipTierUpgrade() {
      return this.$store.state.member.vipTierProgress.upgrade
    },
    memberVipTierProgress() {
      return this.$store.state.member.vipTierProgress.pairing
    },
    // navPermanent() {
    //   if($vuetify.breakpoint.mdAndDown)
    //     return false
    // }
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    affiliateBonus() {
      return this.$store.state.affiliateBonus.affiliateBonusDetail
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    vipProgress() {
      return this.$store.state.member.vipProgress
    },
    referralOverview() {
      return this.$store.state.referral.overview
    },
    redeemCommissionDetailResponseComplete() {
      return this.$store.state.referral.redeemCommissionResponse.complete
    },
    webShareApiSupported() {
      return navigator.share
    },
    watchVipCurLevel() {
      return this.viptierCurLevel
    }
  },
  watch: {
    watchVipCurLevel() {
      this.setTierIcon()
      this.setVipTierDetails()
    },
    memberVipTierProgress() {
      this.setVipTierLevel()
    },
    vipTierUpgrade() {},
    referralOverview() {
      this.share_url = uiHelper.getHostname() + '?refer_code=' + this.referralOverview.my_referral_code
      this.redeemCommissionAmt = this.$store.state.referral.overview.commission_balance.toFixed(2)
    },
    redeemCommissionDetailResponseComplete() {
      let response = this.$store.state.referral.redeemCommissionResponse
      if (response.complete) {
        this.redeemCommissionResponseCompleteDialog(response)
        this.$store.dispatch(`${REFERRAL_RESET_COMMISSION}`)
      }
    },
    isLoggedIn() {
      this.getAffiliateBonusDetail()
      this.getVipTierProgress()
    },
    vipProgress() {
      this.isProgressLoading = false
      let progress = this.$store.state.member.vipProgress
      if (progress && progress.required_deposit_amount && progress.current_deposit_progress) {
        this.progressValue = (progress.current_deposit_progress / progress.required_deposit_amount) * 100
        this.vipProgressLevel = progress.current_level_name.indexOf('Level') != -1 ? progress.current_level_name.split(' ')[1] : ''
      }
    }
  },
  methods: {
    setTierIcon() {
      switch (this.viptierCurLevel) {
        case 0:
          this.tierColorThemeName = 'newmember'
          break
        case 1:
          this.tierColorThemeName = 'bronze'
          break
        case 2:
          this.tierColorThemeName = 'silver'
          break
        case 3:
          this.tierColorThemeName = 'gold'
          break
        case 4:
          this.tierColorThemeName = 'emerald'
          break
        case 5:
          this.tierColorThemeName = 'sapphire'
          break
        case 6:
          this.tierColorThemeName = 'platinum'
          break
        case 7:
          this.tierColorThemeName = 'diamond'
          break
      }
    },
    setVipTierLevel() {
      if (this.memberVipTierProgress && this.memberVipTierProgress.level_sequence != undefined) {
        this.viptierCurLevel = this.memberVipTierProgress.level_sequence
      }
    },
    setVipTierDetails() {
      if (this.viptierCurLevel >= 0 && this.viptierCurLevel < 6) {
        if (this.vipTierUpgrade) {
          if (this.vipTierUpgrade.required_upgrade_deposit && this.vipTierUpgrade.required_upgrade_deposit >= 0) {
            this.requiredUpgradeDeposit = Number(this.vipTierUpgrade.required_upgrade_deposit)
          }

          if (this.vipTierUpgrade.current_upgrade_deposit && this.vipTierUpgrade.current_upgrade_deposit >= 0) {
            this.currentUpgradeDeposit = Number(this.vipTierUpgrade.current_upgrade_deposit)
          }
          this.progressLabel = this.currentUpgradeDeposit.toString() + '/' + this.requiredUpgradeDeposit.toString()
        } else {
          if (this.vipTierUpgrade == null) {
            //pending upgrade tier
            this.progressLabel = locale.getMessage(`label.vipTierPendingUpgrade`)
          }
        }
      } else {
        ///highest tier level in automation = 6 , level 7 is manual upgrade rquired
        if (this.vipTierDowngrade) {
          if (this.vipTierDowngrade.required_downgrade_deposit && this.vipTierDowngrade.required_downgrade_deposit >= 0) {
            this.requiredUpgradeDeposit = Number(this.vipTierDowngrade.required_downgrade_deposit)
          }

          if (this.vipTierDowngrade.current_downgrade_deposit && this.vipTierDowngrade.current_downgrade_deposit >= 0) {
            this.currentUpgradeDeposit = Number(this.vipTierDowngrade.current_downgrade_deposit)
          }
          this.progressLabel = this.currentUpgradeDeposit.toString() + '/' + this.requiredUpgradeDeposit.toString()
        } else {
          //check for whether retention fulfilled
          if (this.vipTierDowngrade == null && this.vipTierUpgrade == null) {
            this.progressLabel = locale.getMessage(`label.vipTierRetentionFulFilled`)
          }
        }
      }

      if (this.requiredUpgradeDeposit > 0) {
        this.vipTierProgressPercent = Math.ceil((this.currentUpgradeDeposit / this.requiredUpgradeDeposit) * 100)
      } else {
        //assume Denominator = 0 mean no need to upgrade / fulfilled
        //either retention fulfilled or pending upgrade
        this.vipTierProgressPercent = 100
      }

      //regarding upgrade/downgrade is null or not as long we 100% then display fulfillment message
      if (this.vipTierProgressPercent === 100) {
        if (this.viptierCurLevel < 6) {
          this.progressLabel = locale.getMessage(`label.vipTierPendingUpgrade`)
        } else {
          this.progressLabel = locale.getMessage(`label.vipTierRetentionFulFilled`)
        }
      }
    },
    getVipTierProgress() {
      if (this.isLoggedIn) {
        this.$store.dispatch(MEMBER_VIP_TIER_PROGRESS)
      }
    },
    redeemCommissionResponseCompleteDialog(response) {
      this.closePageDialog()
      if (response.code != 0 && !response.success) {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.openAlert(true, locale.getMessage(`message.commissionRedeemSuccess`))
      }
    },
    getAffiliateBonusDetail() {
      this.$store.dispatch(`${AFFILIATE_ONE_TIME_BONUS_DETAIL}`)
    },
    goToRewards() {
      let routeName = this.currentCurrency === 'BDT' ? ROUTE_NAME.REWARD_STORE_ITEM : ROUTE_NAME.REWARDS
      this.$router.push({ name: routeName, params: { lang: this.$route.params.lang } })
      // this.$router.push({ name: ROUTE_NAME.REWARD_STORE_ITEM, params: { lang: this.$route.params.lang } })
    },
    redeemCommissionConfirmationDialog() {
      let self = this

      if (this.redeemCommissionAmt) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.redeemConfirmation`)
        dialog.message.push(this.stringFormat(locale.getMessage(`message.ConfirmCommission`), self.$options.filters.currency(this.redeemCommissionAmt, this.currentCurrency)))
        dialog.button.push({
          title: locale.getMessage(`label.confirm`),
          action: () => this.redeemCommission()
        })
        this.openPageDialog(dialog)
      }
    },
    redeemCommission() {
      let obj = { amount: this.redeemCommissionAmt }
      this.$store.dispatch(`${REFERRAL_POST_COMMISSION}`, {
        obj
      })
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    share() {
      const sharing = {
        title: 'Refer a friend now and earn commission',
        text: 'Your referral code is ' + this.referralOverview.my_referral_code + '.Refer a friend now and earn commission now! ',
        url: this.share_url
      }
      if (this.webShareApiSupported) {
        navigator.share(sharing)
      } else {
        let $f = this
        this.$copyText(this.share_url).then(function(e) {
          $f.alertMessage = 'Referral Link Copied!'
          $f.alertSuccess = true
          $f.alertShow = true
        })
      }
    }
  }
}
</script>

<style lang="scss">
.g-text {
  font-size: 15px;
}
.ref-text {
  font-size: 14px;
}
.desktop-left-panel {
  border-radius: 10px;
  //height: 660px;
  overflow-y: auto;

  .grey-card {
    background-color: #f3f5f6 !important;
    border: unset !important;
    margin-top: 8px;
    border-radius: 10px;
    font-size: 0.83rem;

    .card-row {
      align-items: center;
      font-size: 0.81rem;
    }
    .v-progress-linear__determinate {
      background-color: var(--v-newMobilePrimary-base) !important;
    }
    .v-progress-linear__background {
      background-color: #d9d9d9 !important;
    }

    .nav-btn {
      font-size: 0.7rem;
      width: 100%;
      border-radius: 20px;
      background-color: var(--v-newMobilePrimary-base);

      & span {
        width: 100%;
        text-wrap: wrap;
      }
    }
  }

  .panel-blue-text {
    color: #0094ff !important;
  }

  .share-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px 5px;

    .referralCode-div {
      width: 100%;
    }

    .share-btn-div {
      //flex-basis: 20%;
      width: 100%;
    }
  }
  .grey-text-field {
    background-color: #d9d9d9;
    border-radius: 10px;
    width: 100%;
    display: inline-block;
  }

  .left-panel-icon {
    width: 30px;
    height: 30px;
  }

  .share-btn {
    background-color: #0094ff;
    border-radius: 10px;
    width: 100%;
    height: 30px;
    .share-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.desktop-navigation-drawer {
  font-size: 12px;
  border-radius: 20px;
  height: 660px;
  .nav-title {
    color: #acacac;
  }
  .profile-nav-button {
    .mobile-icon {
      fill: #3a3a3a;
      path {
        fill: #3a3a3a;
      }
    }
    .mobile-icon-deposit {
      fill: #3a3a3a !important;
    }
    // &:hover {
    //   color: #0C2AAB !important;
    //   .mobile-icon {
    //     fill: #0C2AAB !important;
    //     path {
    //       fill: #0C2AAB !important;
    //     }
    //   }
    //   .mobile-icon-deposit{
    //     fill: #0C2AAB !important;
    //   }
    // }
  }

  .nav-btn.theme--light.v-list-item--active::before {
    opacity: 0 !important;
  }
}
.profile-nav-active {
  background-color: #3a3a3a !important;
  color: #fff !important;
  .mobile-icon {
    fill: #fff !important;
    path {
      fill: #fff !important;
    }
  }
}

@media (max-width: 1590px) {
  .grey-card {
    .v-btn.v-size--default {
      font-size: 0.7rem !important;
    }
  }
}

@media (max-width: 1271px) {
  .share-div {
    .referralCode-div {
      //flex-basis: 100% !important;
    }
    .share-btn-div {
      //flex-basis: 100% !important;

      .share-btn {
        width: 100% !important;
        flex-basis: 100% !important;
      }
    }
  }
}
</style>
